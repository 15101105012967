import {z} from 'zod'

export const PageImageResource = z.object({
  alt: z.string().nullable(),
  url: z.string().nullable(),
})

export const ReviewResource = z.object({
  // image: PageImageResource,
  quote: z.string(),
  author: z.string(),
  rating: z.string().nullable(),
  image_alt: z.string().optional().nullable(),
  image_url: z.string().optional().nullable(),
})

export const FaqResource = z.object({
  answer: z.string(),
  question: z.string(),
})

export const NewsResource = z.object({
  title: z.string(),
  slug: z.string(),
  content: z.string(),
  created_at: z.string(),
})

export const CityResource = z.object({
  name: z.string(),
  url: z.string(),
  image_alt: z.string().optional().nullable(),
  image_url: z.string().optional().nullable(),
})

export const FooterResource = z.object({
  slogan: z.string(),
})

export const PageSectionResource = z.object({
  id: z.number(),
  slug: z.string().optional(),
  page_id: z.number(),
  title: z.string().nullable(),
  content: z.string().nullable(),
  buttons: z.record(z.string()).nullable(),
  images: z.array(PageImageResource).nullable(),
  additional: z.array(z.union([ReviewResource, FaqResource, CityResource])).nullable(),
})

export const PageResource = z.object({
  id: z.number(),
  slug: z.string(),
  title: z.string(),
  description: z.string(),
  sections: z.array(PageSectionResource),
  footer: FooterResource
})


export type FaqResource = z.infer<typeof FaqResource>
export type NewsResource = z.infer<typeof NewsResource>
export type CityResource = z.infer<typeof CityResource>
export type PageResource = z.infer<typeof PageResource>
export type FooterResource = z.infer<typeof FooterResource>
export type ReviewResource = z.infer<typeof ReviewResource>
export type PageImageResource = z.infer<typeof PageImageResource>
export type PageSectionResource = z.infer<typeof PageSectionResource>
